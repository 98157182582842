import React from "react";
import SEO from "../components/seo";
import { FAQzh } from "../components/TWComponents/faq/FAQzh";
import Layout from "../layouts/zh";

const FaqPage = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO title="常见问题 " />

      <div id="contentWrap">
        <div id="index_content" className="admin-setting">
          <div className="faq">
            <h1 className="text--center">常见问题</h1>
            <div className="container container--800">
              <FAQzh />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default FaqPage;
