import React, { useState } from "react";
import * as Accordion from "@radix-ui/react-accordion";

export const FAQzh = () => {
  const [activeTab, setActiveTab] = useState("item1");

  const handleTabChange = (newValue) => {
    setActiveTab(newValue);
  };

  const getTabStyle = (tabName) => ({
    borderBottom: activeTab === tabName ? "2px solid #52c0e9" : "none",
    color: activeTab === tabName ? "#52c0e9" : "#ACBFC6",
    fontWeight: activeTab === tabName ? "normal" : "350",
  });

  return (
    <div className="tw-w-full tw-mt-16">
      <div className="tw-flex tw-justify-center tw-overflow-scroll tw-overflow-y-hidden md:tw-overflow-hidden tw-gap-4 tw-scrollbar-hidden">
        <button
          style={getTabStyle("item1")}
          className="tw-pb-2 tw-bg-transparent tw-text-[16px] tw-leading-[20px] tw-font-gotham tw-uppercase"
          onClick={() => handleTabChange("item1")}>
          概要信息
        </button>
        <button
          style={getTabStyle("item2")}
          className="tw-pb-2 tw-bg-transparent tw-text-[16px] tw-leading-[20px] tw-font-gotham tw-uppercase"
          onClick={() => handleTabChange("item2")}>
          积分赚取
        </button>
        <button
          style={getTabStyle("item3")}
          className="tw-pb-2 tw-bg-transparent tw-text-[16px] tw-leading-[20px] tw-font-gotham tw-uppercase"
          onClick={() => handleTabChange("item3")}>
          会员福利
        </button>
        <button
          style={getTabStyle("item4")}
          className="tw-pb-2 tw-bg-transparent tw-text-[16px] tw-leading-[20px] tw-font-gotham tw-uppercase"
          onClick={() => handleTabChange("item4")}>
          积分兑换
        </button>
        <button
          style={getTabStyle("item5")}
          className="tw-pb-2 tw-bg-transparent tw-text-[16px] tw-leading-[20px] tw-font-gotham tw-uppercase"
          onClick={() => handleTabChange("item5")}>
          用积分支付
        </button>
      </div>

      <div className="tw-mt-6">
        {activeTab === "item1" && (
          <div className="tw-flex tw-flex-col tw-gap-3">
            <Accordion.Root collapsible type="single" className="">
              <Accordion.Item
                value="item1"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>AYANA奖励的参与品牌有哪些？</span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-px-2">
                  <ol
                    type="a"
                    className="tw-text-sm tw-font-opensans tw-flex tw-flex-col tw-gap-1 tw-mb-0">
                    <li>
                      参与酒店、度假胜地及公寓包括AYANA Midplaza
                      Jakarta、Delonix Hotel Karawang、Delonix Residences
                      Karawang、AYANA Resort Bali、AYANA Segara Bali、AYANA
                      Villas Bali及RIMBA by AYANA Bali。and AYANA Komodo Waecicu
                      Beach
                    </li>
                    <li>其他参与品牌是Riverside高尔夫俱乐部。</li>
                  </ol>
                </Accordion.Content>
              </Accordion.Item>

              <Accordion.Item
                value="item2"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>如何注册AYANA奖励？</span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  <span>
                    会员可以通过
                    <a
                      href="http://rewards.ayana.com/zh/signup"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="tw-ml-1">
                      https://rewards.ayana.com/zh/signup
                    </a>
                    在线注册。
                  </span>
                </Accordion.Content>
              </Accordion.Item>

              {/* Item 3: Membership Card */}
              <Accordion.Item
                value="item3"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>我会收到实体或数字会员卡吗？</span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  目前，AYANA奖励会员不提供实体或数字会员卡。然而，您可以通过登录
                  <a
                    href="https://rewards.ayana.com/zh/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="tw-ml-1">
                    https://rewards.ayana.com/zh/
                  </a>
                  来访问您的账户。
                </Accordion.Content>
              </Accordion.Item>

              {/* Item 4: Membership Benefits */}
              <Accordion.Item
                value="item4"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>作为AYANA奖励会员，我可以获得哪些福利？</span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  所有AYANA奖励会员可以根据会员级别享受AYANA奖励门户“会员福利”部分中列出的福利。然而，请注意，某些福利可能会受到特定条款和条件的限制。
                </Accordion.Content>
              </Accordion.Item>

              {/* Item 5: Sharing Membership */}
              <Accordion.Item
                value="item5"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>我的伴侣和我可以共享一个AYANA奖励会员资格吗？</span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  AYANA奖励会员是以个人为基础授予的。不允许为伴侣提供联合账户或为一个人提供多个账户。
                </Accordion.Content>
              </Accordion.Item>

              {/* Item 6: Point Transfers */}
              <Accordion.Item
                value="item6"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>我想将我的积分转让给另一个人。这可能吗？</span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  很抱歉地通知您，目前禁止将积分转让给其他人。
                </Accordion.Content>
              </Accordion.Item>

              {/* Item 7: Points Expiration */}
              <Accordion.Item
                value="item7"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>积分会过期吗？</span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  如果会员未能在最后一次活动后的24个月内保持活跃状态，账户将被停用，所有已获得的积分将被没收。
                </Accordion.Content>
              </Accordion.Item>

              {/* Item 8: Maintaining Active Status */}
              <Accordion.Item
                value="item8"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>我如何保持我的活跃状态？</span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  会员可以通过网站登录活动，完成合资格住宿，进行合资格消费或兑换AYANA奖励积分来保持活跃状态。
                </Accordion.Content>
              </Accordion.Item>

              {/* Item 9: Contact Information */}
              <Accordion.Item
                value="item9"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>AYANA奖励有联系电话吗？</span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  目前，AYANA奖励只能通过info@ayanarewards.com联系。
                </Accordion.Content>
              </Accordion.Item>

              {/* Item 10: Gold or Platinum Status */}
              <Accordion.Item
                value="item10"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>我如何保持我的金卡或白金卡状态？</span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  会员必须保留自上一次升级或降级或保持状态后的下一个日历年的12月31日之前累积的等级积分或合资格夜晚。
                  未能满足维持其当前等级所需的最低积分或夜晚要求将导致会员等级降级。
                </Accordion.Content>
              </Accordion.Item>

              {/* Item 11: Upcoming Bookings */}
              <Accordion.Item
                value="item11"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    我刚刚完成了预订。为什么它没有在我的即将到来预订部分显示？
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  预订和更改预订可能需要最多72小时才能显示在您的即将到来预订部分。
                </Accordion.Content>
              </Accordion.Item>

              {/* Item 12: Member Rates */}
              <Accordion.Item
                value="item12"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    我刚刚注册了AYANA奖励，或者忘记以会员身份登录。我可以为现有的预订获得会员费率吗？
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  如果会员在预订房间后注册，或者在参与酒店预订房间时未登录，则无权索取会员专属房间优惠。
                </Accordion.Content>
              </Accordion.Item>
            </Accordion.Root>
          </div>
        )}
        {activeTab === "item5" && (
          <div>
            <div className="tw-flex tw-flex-col tw-gap-3">
              <Accordion.Root collapsible type="single" className="">
                <Accordion.Item
                  value="item1"
                  className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                  <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                    <span>如何使用AYANA奖励积分进行预订？</span>
                    <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="tw-h-4 tw-w-4">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 4.5v15m7.5-7.5h-15"
                        />
                      </svg>
                    </span>
                    <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="tw-h-4 tw-w-4">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M5 12h14"
                        />
                      </svg>
                    </span>
                  </Accordion.Trigger>
                  <Accordion.Content className="tw-my-4 tw-pr-8 tw-px-2">
                    <p className="tw-text-sm tw-font-opensans tw-flex tw-flex-col tw-gap-1">
                      当您选择此选项时，您的积分将用于支付您预订所需的积分数量。您可以决定要兑换多少积分来支付预订费用，并使用其他可用支付方式支付剩余金额。
                    </p>
                  </Accordion.Content>
                </Accordion.Item>

                <Accordion.Item
                  value="item2"
                  className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                  <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                    <span>
                      我在注意到这一点之前预订了房间后，可以使用剩余的积分支付房费吗？
                    </span>
                    <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="tw-h-4 tw-w-4">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 4.5v15m7.5-7.5h-15"
                        />
                      </svg>
                    </span>
                    <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="tw-h-4 tw-w-4">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M5 12h14"
                        />
                      </svg>
                    </span>
                  </Accordion.Trigger>
                  <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                    不幸的是，已经预订房间的会员无法使用积分支付功能。积分支付只能在通过我们的网站预订时使用，标记为“符合AYANA奖励积分资格”的价格。
                  </Accordion.Content>
                </Accordion.Item>

                {/* Item 3: Membership Card */}
                <Accordion.Item
                  value="item3"
                  className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                  <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                    <span>使用积分支付需要多少积分？</span>
                    <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="tw-h-4 tw-w-4">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 4.5v15m7.5-7.5h-15"
                        />
                      </svg>
                    </span>
                    <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="tw-h-4 tw-w-4">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M5 12h14"
                        />
                      </svg>
                    </span>
                  </Accordion.Trigger>
                  <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                    使用积分支付所需的最低积分是500奖励积分。
                  </Accordion.Content>
                </Accordion.Item>

                {/* Item 4: Membership Benefits */}
                <Accordion.Item
                  value="item4"
                  className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                  <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                    <span>每1积分的积分支付价值是多少？</span>
                    <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="tw-h-4 tw-w-4">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 4.5v15m7.5-7.5h-15"
                        />
                      </svg>
                    </span>
                    <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="tw-h-4 tw-w-4">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M5 12h14"
                        />
                      </svg>
                    </span>
                  </Accordion.Trigger>
                  <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                    使用积分支付的价值可能会因参与品牌而异。当使用积分支付时，您可以查看当地货币的价值。
                  </Accordion.Content>
                </Accordion.Item>

                {/* Item 5: Sharing Membership */}
                <Accordion.Item
                  value="item5"
                  className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                  <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                    <span>如果我用积分支付，我可以取消预订吗？</span>
                    <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="tw-h-4 tw-w-4">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 4.5v15m7.5-7.5h-15"
                        />
                      </svg>
                    </span>
                    <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="tw-h-4 tw-w-4">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M5 12h14"
                        />
                      </svg>
                    </span>
                  </Accordion.Trigger>
                  <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                    您的预订的取消政策将遵循您选择的价格的取消政策。如果所选价格是可退款的，则使用积分支付的金额将作为积分退款，使用其他支付方式支付的部分将根据您的支付方式退款。
                  </Accordion.Content>
                </Accordion.Item>

                {/* Item 6: Point Transfers */}
                <Accordion.Item
                  value="item6"
                  className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                  <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                    <span>我可以使用积分支付餐厅或水疗费用吗？</span>
                    <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="tw-h-4 tw-w-4">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 4.5v15m7.5-7.5h-15"
                        />
                      </svg>
                    </span>
                    <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="tw-h-4 tw-w-4">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M5 12h14"
                        />
                      </svg>
                    </span>
                  </Accordion.Trigger>
                  <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                    目前，我们不接受使用积分支付餐厅或水疗费用。
                  </Accordion.Content>
                </Accordion.Item>

                {/* Item 7: Points Expiration */}
                <Accordion.Item
                  value="item7"
                  className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                  <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                    <span>
                      虽然我用全部积分预订了房间，但我仍然能够赚取积分吗？
                    </span>
                    <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="tw-h-4 tw-w-4">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 4.5v15m7.5-7.5h-15"
                        />
                      </svg>
                    </span>
                    <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="tw-h-4 tw-w-4">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M5 12h14"
                        />
                      </svg>
                    </span>
                  </Accordion.Trigger>
                  <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                    是的，您可以赚取AYANA奖励积分。
                  </Accordion.Content>
                </Accordion.Item>
              </Accordion.Root>
            </div>
          </div>
        )}
        {activeTab === "item4" && (
          <div>
            <Accordion.Root collapsible type="single" className="">
              <Accordion.Item
                value="item1"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>我想将兑换券的接收者转给其他人。这可以吗？</span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-px-2">
                  <p className="tw-text-sm tw-font-opensans tw-flex tw-flex-col tw-gap-1">
                    是的，允许将您使用的产品或服务的接收者转让给他人。然而，会员必须在使用过程之前单独通知AYANA奖励会员服务。
                  </p>
                </Accordion.Content>
              </Accordion.Item>

              {/* Item 3: Membership Card */}
              <Accordion.Item
                value="item3"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    我刚刚兑换了我的兑换券。然而，我现在想退款。可能吗？
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  一旦兑换券被兑换，就不可取消且不可退款。
                </Accordion.Content>
              </Accordion.Item>

              {/* Item 4: Membership Benefits */}
              <Accordion.Item
                value="item4"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>我没有打印机来打印兑换券。有其他替代方法吗？</span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  除了打印的电子兑换券，您也可以出示从我们的系统收到的“兑换确认”电子邮件或我们会员团队的任何确认电子邮件/信函（如适用）。
                </Accordion.Content>
              </Accordion.Item>

              {/* Item 5: Sharing Membership */}
              <Accordion.Item
                value="item5"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    在免费夜晚兑换期间住宿时，我仍然符合享受会员等级福利的资格吗？
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  是的，您有权享受所有适用的会员福利。但是，如果您将产品/服务转让给其他人，接收者将被归类为非会员，不会获得任何会员等级福利。
                </Accordion.Content>
              </Accordion.Item>

              {/* Item 6: Point Transfers */}
              <Accordion.Item
                value="item6"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>我的住宿会有额外费用吗？</span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  根据酒店，免费夜晚兑换凭证可能不包括额外人员费用和附加费，如果您的住宿处于旺季或高峰期。
                </Accordion.Content>
              </Accordion.Item>

              {/* Item 7: Points Expiration */}
              <Accordion.Item
                value="item7"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>我可以取消或重新安排我的确认免费夜晚兑换吗？</span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  是的，只要符合品牌的取消截止日期，您可以重新安排或取消您的免费住宿房间预订。但是，请确保您的重新安排住宿在凭证的到期日期之前的时间范围内。
                </Accordion.Content>
              </Accordion.Item>
              <Accordion.Item
                value="item8"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>兑换凭证的到期日期是什么意思？</span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  到期日期表示会员可以使用凭证的最后日期。对于房间预订，这意味着会员必须在到期日前退房。对于服务，会员必须在到期日前使用凭证。
                </Accordion.Content>
              </Accordion.Item>
              <Accordion.Item
                value="item9"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>我为什么没有收到我的兑换凭证？</span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  如果您没有收到您的兑换凭证，请联系我们。这可能是因为您曾经退订过邮件列表。
                </Accordion.Content>
              </Accordion.Item>
            </Accordion.Root>
          </div>
        )}
        {activeTab === "item3" && (
          <div>
            <Accordion.Root collapsible type="single" className="">
              <Accordion.Item
                value="item1"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    作为AYANA奖励金卡或AYANA奖励白金会员，我的免费1级房间升级是否有保障？
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-px-2">
                  <p className="tw-text-sm tw-font-opensans tw-flex tw-flex-col tw-gap-1">
                    很遗憾，免费1级房间升级在您到达前无法保证。它们将根据参与品牌的可用性和停用日期应用。
                  </p>
                </Accordion.Content>
              </Accordion.Item>

              <Accordion.Item
                value="item2"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>我将会收到哪种类型的房间升级？</span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  您的免费1级房间升级仅适用于您最初预订的相同房间类别。这不包括例如从房间到套房、多卧室升级（例如，房间到2卧室），别墅和公寓的升级。
                </Accordion.Content>
              </Accordion.Item>
              <Accordion.Item
                value="item3"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    我注意到我有资格享受提前入住和延迟退房。这是保证的吗？
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  AYANA奖励金卡和AYANA奖励白金会员的提前入住和延迟退房取决于您到达时的可用性。遗憾的是，我们无法在您到达前保证它们。
                </Accordion.Content>
              </Accordion.Item>

              <Accordion.Item
                value="item4"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    作为AYANA奖励白金会员，我注意到我有资格享用免费早餐，但我已经预订了包含早餐的房间。我可以将此免费福利给予他人吗？
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  如果会员已经预订了包含早餐的房间，他们自动没有资格享受任何额外的免费早餐福利。
                </Accordion.Content>
              </Accordion.Item>

              <Accordion.Item
                value="item5"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    我是通过第三方网站（不是直接预订）预订的AYANA奖励白金会员。我仍有资格享受免费早餐吗？
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  AYANA奖励白金会员的免费早餐仅在会员通过直接渠道预订时可用。
                </Accordion.Content>
              </Accordion.Item>

              <Accordion.Item
                value="item6"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>哪些预订渠道被分类为直接渠道？</span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  直接渠道是指通过官方AYANA网站（www.ayana.com）、AYANA奖励网站（https://rewards.ayana.com/）或我们的酒店预订服务进行的房间预订。
                </Accordion.Content>
              </Accordion.Item>
            </Accordion.Root>
          </div>
        )}
        {activeTab === "item2" && (
          <div>
            <Accordion.Root collapsible type="single" className="">
              <Accordion.Item
                value="item1"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>我如何为我的酒店住宿赚取积分？</span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-px-2">
                  <p className="tw-text-sm tw-font-opensans tw-flex tw-flex-col tw-gap-1">
                    您可以通过在合资格价格和渠道预订房间来赚取酒店住宿积分。此外，任何合资格的附加费用结算到您的房间也将赚取积分。
                  </p>
                </Accordion.Content>
              </Accordion.Item>

              <Accordion.Item
                value="item2"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    如果我使用其他支付方式支付附加费用（例如，餐厅或水疗费用），例如在商店使用现金或卡，会发生什么？我还能赚取积分吗？
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  是的，即使您使用其他支付方式，您仍然可以在附加费用上赚取积分。只需在账单结算时通知我们的工作人员您想赚取积分。
                </Accordion.Content>
              </Accordion.Item>
              <Accordion.Item
                value="item3"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    我没有住在酒店。我还能从我的餐厅或水疗费用中赚取积分吗？
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  是的，即使您不住在品牌酒店，您也可以从您的餐厅和水疗费用中赚取积分。在账单结算期间简单告知我们的员工，他们将协助您赚取积分。
                </Accordion.Content>
              </Accordion.Item>

              <Accordion.Item
                value="item4"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    我住在酒店，房价不合资格或预订渠道不合资格，我还能赚取积分吗？
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  您没有资格通过不合资格的房价赚取积分。不过，您仍可以从您住宿期间产生的合资格附加费用中赚取积分。只需确保这些费用在您的房间中结清即可。
                </Accordion.Content>
              </Accordion.Item>

              <Accordion.Item
                value="item5"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    我正在预订和支付酒店的房间。然而，我并没有真正住在酒店。我还能赚取积分吗？
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  为了赚取积分，您必须身体住宿在酒店。如果您仅作为房间的预订者而不住在酒店，您无资格赚取积分。
                </Accordion.Content>
              </Accordion.Item>

              <Accordion.Item
                value="item6"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    我已经在参与品牌预订了房间，为什么我的积分还没有记入我的会员账户？
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  积分将在您退房日期后的7个工作日内计入您的会员账户。
                </Accordion.Content>
              </Accordion.Item>
              <Accordion.Item
                value="item7"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>我如何计算我赚取的积分？</span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  在参与品牌每消费10,000印尼盾将获得1分积分，但在Delonix Hotel
                  Karawang、Delonix Residences
                  Karawang和Riverside高尔夫俱乐部每消费15,000印尼盾将获得1分积分。请注意，税费、服务费和小费不包括在积分计算中。
                </Accordion.Content>
              </Accordion.Item>
              <Accordion.Item
                value="item8"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    我在预订后刚注册了AYANA奖励。可以关联我的会员并获得积分吗？
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  如果会员使用与其AYANA奖励会员账户关联的相同电子邮件地址预订房间，则预订将在预订日期后72小时内链接到其AYANA奖励账户。积分将在退房后最多7个工作日内记入会员账户。
                </Accordion.Content>
              </Accordion.Item>
              <Accordion.Item
                value="item9"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>我有尚未领取的过往住宿/交易。我还能赚取积分吗？</span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  您可以在退房日期和交易日期后的60天内领取过往住宿/交易的积分。在此时间范围外提出的索赔将无法处理。
                </Accordion.Content>
              </Accordion.Item>
              <Accordion.Item
                value="item10"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>我的AYANA奖励账户可以赚取多少个房间的积分？</span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  <span>
                    如果满足以下条件，您可以获得最多为同一住宿时间段预订的5间房间积分：
                  </span>
                  <ul>
                    <li>房间以您的名义预订。</li>
                    <li>您实际上住在进行了多个房间预订的酒店。</li>
                  </ul>
                </Accordion.Content>
              </Accordion.Item>
              <Accordion.Item
                value="item11"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    为什么我在之前的住宿中没有赚取任何住宿夜晚或积分？
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  <span>有几种不符合积分信用资格的费率，如下所列：</span>
                  <ul>
                    <li>第三方在线或离线旅行社费率</li>
                    <li>活动、会议、会议或婚礼的团体费率</li>
                    <li>免费住宿</li>
                    <li>通过AYANA奖励凭证兑换的免费夜晚奖励</li>
                    <li>
                      批发商费率、旅行社费率、航空公司乘务员费率、航空公司员工费率、房费计入主账户和可获得佣金的费率
                    </li>
                    <li>其他未提及的特殊费率，例如家庭和朋友费率等</li>
                  </ul>
                </Accordion.Content>
              </Accordion.Item>
            </Accordion.Root>
          </div>
        )}
      </div>
    </div>
  );
};
